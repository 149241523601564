import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  { 
    path: '/', 
    redirect: '/home' 
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home.vue')
  },
  {
    path: '/login',
    component: () => import('../views/login.vue')
  },

  {
    path: '/index',
    name: 'index',
    redirect: '/home',
    component: () => import('../views/index.vue'),
    children: [
      // 最新
      {
        // 实时监测
        path: '/detection',
        name: 'detection',
        component: () => import('../views/cable/detection.vue')
      },
      {
        // 电缆列表
        path: '/cableList',
        name: 'cableList',
        component: () => import('../views/cable/cableList.vue')
      },
      {
        // 报警信息
        path: '/pInfo',
        name: 'pInfo',
        component: () => import('../views/police/pInfo.vue')
      },
      {
        // 报警设置
        path: '/pSet',
        name: 'pSet',
        component: () => import('../views/police/pSet.vue')
      },
      {
        // 预警信息
        path: '/yInfo',
        name: 'yInfo',
        component: () => import('../views/police/yInfo.vue')
      },
      {
        // 预警设置
        path: '/ySet',
        name: 'ySet',
        component: () => import('../views/police/ySet.vue')
      },
      {
        // 报表管理
        path: '/report',
        name: 'report',
        component: () => import('../views/report/report.vue')
      },
      {
        // 账户列表
        path: '/zList',
        name: 'zList',
        component: () => import('../views/organization/zList.vue')
      },
      {
        // 账户创建
        path: '/zAdd',
        name: 'zAdd',
        component: () => import('../views/organization/zAdd.vue')
      },
      {
        // 组织创建
        path: '/organAdd',
        name: 'organAdd',
        component: () => import('../views/organization/organAdd.vue')
      },
      {
        // 角色创建
        path: '/roleAdd',
        name: 'roleAdd',
        component: () => import('../views/organization/roleAdd.vue')
      },
      // 最新


      {
        // 用能监测
        path: '/monitor',
        name: 'monitor',
        component: () => import('../views/monitor/monitor.vue')
      },
      {
        // 用能监测-设备详情
        path: '/monitor/detail',
        name: 'monitor-detail',
        component: () => import('../views/monitor/detail.vue')
      },
      {
        // 设备管理
        path: '/facility',
        name: 'facility',
        component: () => import('../views/facility/facility.vue')
      },
      {
        // 报警监测，报警监控
        path: '/alarm/monitor',
        name: 'alarm-monitor',
        component: () => import('../views/alarm/alarm-monitor.vue')
      },
      {
        // 报警监测，报警统计
        path: '/alarm/statistic',
        name: 'alarm-statistic',
        component: () => import('../views/alarm/alarm-statistic.vue')
      },
      {
        // 电量对比
        path: '/contrast',
        name: 'contrast',
        component: () => import('../views/energy/contrast.vue')
      },
      {
        // 电量排名
        path: '/rank',
        name: 'rank',
        component: () => import('../views/energy/rank.vue')
      },
      {
        // 趋势分析
        path: '/trend',
        name: 'trend',
        component: () => import('../views/analy/trend.vue')
      },
      {
        // 用能报表
        path: '/energy/chart',
        name: 'energy-chart',
        component: () => import('../views/analy/energy-chart.vue')
      },
      {
        // 电费报表
        path: '/electricity/chart',
        name: 'electricity-chart',
        component: () => import('../views/analy/electricity-chart.vue')
      },
      {
        // 账号列表
        path: '/account',
        name: 'account',
        component: () => import('../views/user/account.vue')
      },
      {
        // 账号创建
        path: '/account/add',
        name: 'add',
        component: () => import('../views/user/add.vue')
      },
      {
        // 项目创建
        path: '/project',
        name: 'project',
        component: () => import('../views/user/project.vue')
      },

    ]
  }
]
const router = new VueRouter({
  routes
})

// 挂载路由导航守卫
// router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // from 代表从哪个路径跳转而来
  // next 是一个函数，表示放行
  //     next()  放行    next('/login')  强制跳转
  // if (to.path === '/login') return next()
  // 获取token
  // const tokenStr = window.sessionStorage.getItem('token')
  // if (!tokenStr) return next('/login')
  // next()
// })

export default router
